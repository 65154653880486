import React from "react"
import IntegrationTemplate from "../../templates/integration"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import IntegrationStorageContent from 'components/content/integration-storage'
import { meta } from '../../configs/storage-providers';
const ContentPage = () => (
  <IntegrationTemplate
    key="upcloud"
    name="UpCloud"
    shortDescription="Store website files and database backups on UpCloud. Effortless global infrastructure. Enterprise-grade cloud platform for SMEs."
    website={"https://upcloud.com/products/block-storage"}
    links={[{text: 'Documentation', url: '/docs'}]}
    category="Storage"
    logo="upcloud-ico.png"
    metaTitle={meta.title.replace('##provider##', 'UpCloud')}
    metaDescription={meta.description.replace('##provider##', 'UpCloud')}
  >
    <IntegrationStorageContent
      keyRef="upcloud"
      service="UpCloud"
      provider="UpCloud"
      learnTags={['upcloud']}
      contentBlocks={[{title: 'Bring your own UpCloud bucket', text: 'UpCloud makes things simple.. It works with very basic setup and once added to your SimpleBackups account, you can automatically back up files and databases to your UpCloud bucket.\n'}]}
    />
  </IntegrationTemplate>
)

export default ContentPage
